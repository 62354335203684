<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Daily CA</p>
    </div>
    <v-card class="mb-6 pa-2">
      <v-card-text>
        <div class="d-flex flex-row mb-6">
          <v-btn small @click="setTotal">Total</v-btn>
          <v-text-field
            v-model="searchStr"
            label="Rechercher un site"
            @input="search"
            class="ml-2"
            dense
          ></v-text-field>
          <v-text-field
            v-model="searchColStr"
            label="Rechercher une colonne"
            @input="search"
            class="ml-2"
            dense
          ></v-text-field>
          <v-spacer></v-spacer>
          <date-picker-single-day
            identifier="dayFilter"
            dateInit="yesterday"
            @onSelect="setSelectedDay"
            dense="dense"
            customClass="ml-4"
            hint=""
          ></date-picker-single-day>
          <v-btn
            v-show="showDevMode"
            @click="getData()"
            outlined
            dense
            class="mx-1"
          >
            Reload
          </v-btn>
          <dot-menu
            @exportCsv="exportCSV"
            :csvButton="{
              csvData,
              filename: `Prog-GSS-export-${this.dateSelected}.csv`,
            }"
            @exportExcel="exportExcel"
            :excelButton="{
              excelData,
              filename: `Prog-GSS-export-${this.dateSelected}.xlsx`,
              disabled: false,
            }"
          />
        </div>
        <div v-if="filteredData.length !== 0">
          <v-simple-table dense fixed-header height="500">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(value, key, index) in filteredData[0]"
                    :key="index"
                    class="text-left"
                    :class="`field_${key.replace(/\s+/g, '_').toLowerCase()}`"
                  >
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in filteredData" :key="i">
                  <td
                    v-for="(value, key, index) in item"
                    :key="index"
                    class="cell"
                    :class="`value_${key.replace(/\s+/g, '_').toLowerCase()}`"
                  >
                    <span v-html="value"></span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else>
          {{ noDataMessage }}
        </div>
      </v-card-text>
    </v-card>
    <v-card class="export-month">
      <v-card-text>
        <v-row>
          <v-col cols="6" class="text-right description">
            Exporter les jours sur un mois entier
          </v-col>
          <!-- label="Exporter un mois" -->
          <v-col cols="2" class="text-right month-picker">
            <date-picker-single-month
              identifier="monthFilter"
              dateInit="current"
              @onSelect="setSelectedExportMonth"
              dense="dense"
              customClass="ml-4"
              hint=""
            ></date-picker-single-month>
          </v-col>
          <v-col cols="2" class="text-right group-wrappers">
            <v-switch
              v-model="groupWrappers"
              :label="`Grouper GAM Wrappers`"
              dense="dense"
            ></v-switch>
          </v-col>
          <v-col cols="2" class="submit">
            <!-- <v-btn :loading="monthBtnLoader" @click="exportMonth">
              Exporter
            </v-btn> -->
            <export-excel-v-button
              :excelData="excelMonth.data"
              :filename="excelMonth.filename"
              :loading="monthBtnLoader"
              @exportExcel="exportMonthExcel"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { Konami, KonamiManager } from "@/utils/konami";
import DotMenu from "@/components/common/menus/DotMenu";
import DatePickerSingleDay from "@/views/filters/datetime/DatePickerSingleDay";
import DatePickerSingleMonth from "@/views/filters/datetime/DatePickerSingleMonth";
// import { downloadCsvFile } from "@/utils/formatting";
import { saveRmraView } from "@/utils/rmr-analytics";
import ExportExcelVButton from "@/components/common/menus/buttons/ExportExcelVButton";

let defaultNoDataMessage = "Retrieving data ...";

export default {
  name: "SitesPartnerViewsByDate",
  props: {},
  components: {
    DatePickerSingleDay,
    DatePickerSingleMonth,
    DotMenu,
    ExportExcelVButton,
  },
  watch: {},
  created() {
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.showDevMode = true; // d e v
      }),
    ]);
  },
  data() {
    return {
      excelMonth: {
        data: new Blob([""], { type: "text/plain" }),
        filename: "Programmatic-export-month",
      },
      showDevMode: false,
      groupWrappers: true,
      monthBtnLoader: false,
      monthFlag: false,
      componentKey: 0,
      usePrettyNumbers: true,
      dateSelected: null,
      monthSelected: null,
      filteredData: [],
      searchStr: "",
      searchColStr: "",
      fixedCols: ["Site Name", "Sessions", "Views", "Pvs", "Rpm", "Ca"],
      searchTimeout: null,
      noDataMessage: defaultNoDataMessage,
      data: {
        /*

      */
      },
      csvData: "",
      excelData: new Blob([""], { type: "text/plain" }),
      filteredData: {},
    };
  },
  methods: {
    setSelectedExportMonth(month) {
      console.log("setSelectedExportMonth month", month);
      this.monthSelected = month;
    },
    async exportMonthExcel() {
      console.log("SitesPartnerViewsByDate exportMonthExcel");
      if (!this.monthSelected) {
        alert("missing params");
        return false;
      }
      const url = "/programmatic/site-views-by-day/export-month";
      const queryParams = {
        month: this.monthSelected,
        group_wrappers: this.groupWrappers,
      };
      // console.log("url", url, queryParams);
      this.monthBtnLoader = true;
      const { data } = await axios.get(url, {
        params: queryParams,
        responseType: "blob",
      });
      this.monthBtnLoader = false;
      this.excelMonth.filename = `Programmatic-export-month-${this.monthSelected}`;
      this.excelMonth.data = data;
      // console.log("response", data);
      // downloadCsvFile(
      //   data,
      //   `Programmatic-export-month-${this.monthSelected}.csv`
      // );
    },
    setTotal() {
      this.searchStr = this.searchStr.trim().toLowerCase().includes("total")
        ? ""
        : "TOTAL";
      this.search();
    },
    async exportCSV() {
      if (!this.dateSelected) {
        alert("missing params");
        return false;
      }
      const url = "/programmatic/site-views-by-day/export/csv";
      const queryParams = {
        day: this.dateSelected,
      };
      //console.log("url", url, queryParams);
      const { data } = await axios.get(url, { params: queryParams });
      //console.log("response", data);
      this.csvData = data;
    },
    async exportExcel() {
      if (!this.dateSelected) {
        alert("missing params");
        return false;
      }
      const url = "/programmatic/site-views-by-day/export/xls";
      const queryParams = {
        day: this.dateSelected,
      };
      const { data } = await axios.get(url, {
        params: queryParams,
        responseType: "blob",
      });
      this.excelData = data;
    },
    setSelectedDay(day) {
      //console.log("setSelectedDay identifier", identifier);
      this.dateSelected = day;
      this.getData();
    },
    prettyNbs(x, sep = " ") {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    async getData() {
      this.data = {};
      this.filteredData = {};
      this.noDataMessage = defaultNoDataMessage;
      const url = "/programmatic/site-views-by-day";
      const queryParams = { day: this.dateSelected };
      const {
        data: { error, items },
      } = await axios.get(url, { params: queryParams });
      if (error !== undefined) {
        this.noDataMessage = error;
        return;
      }
      if (items.length == 0) {
        this.noDataMessage = "No data";
        return;
      }
      // JSON.parse(JSON.stringify(response.data));
      this.data = items;
      this.setFilteredData();
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredData();
      }, 500);
    },
    setFilteredData() {
      const filteredData = [];
      const searchStrLC = this.searchStr.trim().toLowerCase();
      const searchColStrLC = this.searchColStr.trim().toLowerCase();
      const [positiveSearchStrs, negativeSearchStrs] =
        this.separateWords(searchStrLC);
      const [positiveSearchCols, negativeSearchCols] =
        this.separateWords(searchColStrLC);

      for (let i = 0; i < this.data.length; i++) {
        const rowSiteStr = this.data[i]["Site Name"].toLowerCase();
        const hasPositiveSearchStrs = this.oneOfListIsIncluded(
          positiveSearchStrs,
          rowSiteStr
        );
        const hasNegativeSearchStrs = this.oneOfListIsIncluded(
          negativeSearchStrs,
          rowSiteStr
        );
        if (
          this.searchStr == "" ||
          // rowSiteStr.includes(searchStrLC) ||
          (hasPositiveSearchStrs && !hasNegativeSearchStrs) ||
          rowSiteStr == "total"
        ) {
          //let obj = this.data[i];
          let obj = structuredClone(this.data[i]);
          if (searchColStrLC != "") {
            obj = {};
            for (const property in this.data[i]) {
              const propertyLC = property.toLowerCase();
              const hasPositiveSearchCols = this.oneOfListIsIncluded(
                positiveSearchCols,
                propertyLC
              );
              const hasNegativeSearchCols = this.oneOfListIsIncluded(
                negativeSearchCols,
                propertyLC
              );
              if (
                this.fixedCols.includes(property) ||
                (hasPositiveSearchCols && !hasNegativeSearchCols)
                //property.toLowerCase().includes(searchColStrLC)
              ) {
                const val = this.data[i][property];
                obj[property] = val;
                // obj[property] = this.isNumber(val)
                //   ? Math.round((val * 100) / 0.9, 2) / 100
                //   : val;
              }
            }
          }
          filteredData.push(obj);
        }
      }
      this.filteredData = filteredData;
    },
    isNumber(str) {
      return !isNaN(parseFloat(str)) && isFinite(str);
    },
    oneOfListIsIncluded(strList, str) {
      return strList.some((substr) => str.toLowerCase().includes(substr));
    },
    allOfListIncludes(strList, str) {
      return strList.some((substr) => !str.toLowerCase().includes(substr));
    },
    separateWords(str) {
      // const inputString = "example + foo - dashes - blabla + youhou ";
      // const [positiveSearchCols, negativeSearchCols] = separateWords(inputString);
      const words = str.split(/(\s)/).filter((word) => word.trim() !== "");
      const positivePrefixWords = [];
      const negativePrefixWords = [];
      let lastPrefix = "+";
      for (let i = 0; i < words.length; i++) {
        if (["+", "-"].includes(words[i])) {
          lastPrefix = words[i];
          continue;
        }
        if (lastPrefix === "-") {
          negativePrefixWords.push(words[i]);
        } else {
          positivePrefixWords.push(words[i]);
        }
      }
      return [positivePrefixWords, negativePrefixWords];
    },
    dateToStr(date) {
      return date.toISOString().split("T")[0];
    },
  },
  computed: {},
  mounted() {
    // this.getData();
    saveRmraView(this.$route);
  },
  filters: {},
};
</script>
<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}

.export-month .description {
  margin-top: 10px;
  font-size: 1.1em;
}
.export-month .month-picker {
  font-size: 11px;
}
.export-month .group-wrappers {
  margin-top: -10px;
}
.export-month .submit {
  font-size: 11px;
}
</style>
<style scoped>
.select-year {
  width: 30px;
}
.select-year {
  width: 250px;
}
th {
  text-transform: capitalize;
  font-size: 11px;
}
.cell {
  text-align: right;
}
.cell span {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.cell span {
  font-size: 0.8em;
}
td {
  z-index: 0;
}
.value_site_id,
.value_site_name {
  position: sticky;
  width: 5em;
  left: 0;
  top: auto;
  z-index: 12;
  background-color: white;
}
.field_site_name {
  z-index: 15 !important;
}
</style>
